import Cookies from 'js-cookie'
import { isEmpty } from 'lodash-es'

export default {
    /**
     * @param {string} key
     * @param value
     * @param {Object} opts
     */
    setItem(key, value, opts = {}) {
        if (isEmpty(value)) {
            return
        }

        opts = {
            expires: 7,
            ...opts,
        }

        Cookies.set(key, value, opts || {})
    },

    /**
     * @param {string} key
     */
    removeItem(key) {
        Cookies.remove(key)
    },

    /**
     * @param {string} key
     */
    has(key) {
        return !isEmpty(Cookies.get(key))
    },

    /**
     * @param {string} key
     * @param defaultValue
     */
    getItem(key, defaultValue = null) {
        return Cookies.get(key) || defaultValue
    },

    /**
     * @param {string} key
     * @param defaultValue
     */
    pullItem(key, defaultValue = null) {
        const result = Cookies.get(key) || defaultValue
        Cookies.remove(key)

        return result
    },
}
